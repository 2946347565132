<template>
	<div>
		<form @submit.prevent="save" id="facility">
			<div class="row mb-3">
				<div class="col-12 col-md-6 col-lg-8">
					<h1>Facility M1 Motorcycle Data</h1>
				</div>
				<div class="col-12 col-md-6 col-lg-4 text-right">
					<button type="button" class="btn btn-light ml-1" v-on:click="close()"><i class="fa-regular fa-times" aria-hidden="true"></i> Close</button>
					<button type="submit" class="btn btn-primary ml-1"><i class="fa-regular fa-save" aria-hidden="true"></i> Save</button>
				</div>
			</div>

			<div class="row mb-5">
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Upload Facility M1 Motorcycle Data</b></div>

						<div class="card-body">
							<ul>
								<li>This must be an Excel file.</li>
								<li>There must be a sheet called "Urban Rates" exactly matching the format of the example. <i class="fa-regular fa-arrow-right"></i></li>
								<li>There must be a sheet called "Rural Rates" exactly matching the format of the example. <i class="fa-regular fa-arrow-right"></i></li>
							</ul>

							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="excel" class="required">Choose Excel File</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="file" id="excel" name="excel" required />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Example of what the Excel sheets should look like</b></div>

						<div class="card-body">
							<img :src="retina('/images/facility_example.jpg')" alt="Facility Excel file example." class="example" />
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<style scoped>
	.example
	{
		width: 1000px;
		max-width: 100%;
		height: auto;
	}
</style>

<script>
	export default {
		name: 'FacilityData',
		methods: {
			save()
			{
				var formData = new FormData(document.getElementById("facility"));
				this.$store.commit("startLoading");

				this.CORS2("POST", "/facility/rates", formData,
					() =>
					{
						this.$store.commit("stopLoading");
						this.showSuccess("Data Updated", "Facility M1 data has been updated.", true, null);
					}, (response) =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error updating M1 data: " + response.responseText, true, null);
					});
			},
			close()
			{
				this.$router.push("/");
			}
		}
	}
</script>
